$main: #393e4a;
$grey-text: #8c8c8c;
$grey: #dedede;
$grey-3: #faf9fe;
$label: #ededed;
$white: #fff;
$second: #f5f2dd;
$purple: #a75fa6;
$background: #fcfbf4;
$error: #e4625d;
$success: #83d46f;
$warning: #ffd25e;
$gradient: linear-gradient(180deg, #a953d8 0%, #ed7b7d 100%);
$radius: 10px;
$medium-radius: 5px;
$small-radius: 2px;

@mixin wasabi-font {
	font-family: "Wasabi", cursive;
}
@mixin shadow {
	background: $white;
	border-radius: $radius;
	box-shadow: 0px 0px 20px 0px rgba(202, 201, 215, 0.35);
	padding: 1.5rem;
}
@mixin textGradient {
	background: $gradient;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}
@mixin spikesBackground {
	background: url("../Assets/spikes.png");
	background-size: 200px;
	background-attachment: fixed;
}

@mixin transition {
	transition: all .2s ease-in-out;
}
