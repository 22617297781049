@import "./Global.scss"

@media screen and (max-width: 1280px)
    header
        padding: 20px 0 10px
        margin: 12px
        &:before, &:after
            height: 100%
        .logo
            img
                width: 80px
            h1
                font-size: 48px
        .subtitle
            font-size: 12px
            margin-top: 0px
    .subheader
        .main-title
            font-size: 28px
@media screen and (max-width: 768px)
    header
        padding: 12px 0 4px
        margin: 12px 12px 24px
        border-radius: $medium-radius
        &:before, &:after
            height: 100%
        &:before
        &:after
        .logo
            img
                width: 50px
                margin-right: 8px
            h1
                font-size: 32px
        .subtitle
            font-size: 10px
    .subheader
        .main-title
            font-size: 24px
    .products-list
        display: block
        article
            &#japanese, &#menus
                .col-1
                    grid-gap: 12px
                .col-2
                    display: block
            .title
                font-size: 16px
                span
        .product
            margin-top: 6px

@media screen and (max-width: 650px)
    .products-list
        article
            &#japanese
                .col-1
                    grid-template-columns: 1fr 1fr

@media screen and (max-width: 500px)
    header
        padding: 12px 0 4px
        margin: 0px 0px 24px
        border-radius: 0
        position: sticky
        top: 0
        z-index: 10
        &:before, &:after
            display: none
        .logo
            img
                width: 45px
                margin-right: 8px
            h1
                font-size: 28px
        .subtitle
            display: none
    .subheader
        .main-title
            font-size: 24px
    .title
        font-size: 12px
    .main-wrapper
        aside
            position: absolute
            right: 100%
            .nav li
                font-size: 20px
                &:not(:last-child)
                    margin-bottom: 16px
    .products-list
        article
            .title
                top: 35px
    .menu-head
        top: 86px
