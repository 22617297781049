@import "./Styles/Global.scss"

@font-face
	font-family: "Wasabi"
	src: url("./Assets/Fonts/Wasabi-Regular.woff") format("woff")

// Framework
body
	background: $background
	font-family: Montserrat, Arial, sans-serif
	font-size: 16px
	line-height: 1.2rem
	color: $main
.title
	@include textGradient
	font-weight: 700
	text-transform: uppercase
	margin-bottom: 8px
.title-underline
	position: relative
	padding-bottom: 4px
	&:before
		content: ""
		position: absolute
		display: inline-block
		width: 30px
		height: 3px
		background: $gradient
		bottom: 0px
	&.center:before
		left: 50%
		transform: translateX(-50%)

// Header
header
	background: $main
	color: $background
	background-size: auto 80%
	max-width: 1200px
	margin: 28px auto
	border-radius: $radius
	position: relative
	padding: 40px 0 20px
	display: flex
	align-items: center
	justify-content: center
	flex-direction: column
	overflow: hidden
	&:before, &:after 
		content: ""
		position: absolute
		bottom: -24%
		width: 20%
		height: 160px
		background: url('./Assets/lotus.svg') no-repeat center 100%
		background-size: 100% 100%
	&:before
		left: -25px
	&:after
		right: -25px
		transform: rotateY(180deg)
	.logo
		display: flex
		align-items: center
		justify-content: center
		img
			width: 110px
			margin-right: 18px
		h1
			font-size: 68px
			font-family: "Wasabi"
			color: $second
	.subtitle
		color: #E2E2E2
		font-size: 14px
		margin-top: 8px
.subheader
	text-align: center
	.main-title
		font-size: 34px
		font-weight: 700

// Wrapper
.main-wrapper
	font-size: 14px
	max-width: 1200px
	margin: 38px auto
	padding: 0 12px
	max-width: 1002px
	display: flex
	align-items: flex-start
	gap: 16px
	aside
		padding-right: 16px
		position: sticky
		top: 24px
		max-height: calc(100vh - 48px)
		overflow-y: scroll
		background: linear-gradient($background 33%, rgba(255,255,255, 0)), linear-gradient(rgba(255,255,255, 0), $background 66%) 0 100%, radial-gradient(farthest-side at 50% 0, rgba(171,171,171, 0.5), rgba(0,0,0,0)), radial-gradient(farthest-side at 50% 100%, rgba(171,171,171, 0.5), rgba(0,0,0,0)) 0 100%
		background-color: $background
		background-repeat: no-repeat
		background-attachment: local, local, scroll, scroll
		background-size: 100% 69px, 100% 69px, 100% 23px, 100% 23px
	.products-list
		width: 100%

// Categories navigation
.nav
	font-size: 14px
	font-weight: 700
	text-transform: uppercase
	@include textGradient
	li
		cursor: pointer
		&:not(:last-child)
			margin-bottom: 4px

.mobile-nav
	position: absolute
	width: 30px
	height: 20px
	top: 50%
	transform: translateY(-50%)
	left: 12px
	display: flex
	align-items: center
	span
		width: 100%
		height: 3px
		background: $background
		display: block
		border-radius: $small-radius
		&::before, &::after
			content: ""
			position: absolute
			height: 3px
			background: $background
			border-radius: $small-radius
		&::before
			width: 80%
			top: 0
		&::after
			width: 60%
			bottom: 0
.nav-wrap.nav-popup
	position: fixed
	background: $background
	z-index: 9
	bottom: 0
	left: 0
	width: 100vw
	height: calc(100vh - 35px)
	display: flex
	align-items: center
	justify-content: center
	opacity: 0
	visibility: hidden
	@include transition
	&:before
		content: ""
		position: absolute
		z-index: -1
		bottom: -50px
		right: -50px
		width: 90%
		height: 40%
		background: url('./Assets/lotus.svg') no-repeat 100% 100%
		background-size: 100% 100%
	&.opened
		opacity: 1
		visibility: visible

// Filters
.filters
	.filter
		margin-top: 24px
		&-title
			font-weight: 600
		ul
			display: flex
			flex-direction: column
			align-items: flex-start
		.checkbox
			cursor: pointer
			margin-top: 6px
			display: inline-flex
			align-items: center
			span
				width: 14px
				height: 14px
				display: inline-block
				border: 1px solid $grey-text
				border-radius: $small-radius
				margin-right: 4px
				position: relative
				@include transition
				&:before
					content: ""
					position: absolute
					width: 10px
					height: 10px
					background: $purple
					top: 1px
					left: 1px
					transform: scale(0)
					@include transition
			&:hover, &.active:hover
				color: $purple
				span
					border-color: $purple
					&::before
						background: $purple
			&.active
				color: $main
				span
					border-color: $main
				span:before
					background: $main
					transform: scale(1)
			p
				@include transition

// Products List
.products-list
	display: grid
	grid-template-columns: 1fr 1fr
	grid-gap: 32px
	margin-top: -24px
	article
		&#entry
			grid-row: 1/3
		&#accompaniment
			grid-row: 2/3
			grid-column: 2/3
		&#japanese, &#wine, &#menus
			grid-column: 1 / 3
			.col-1
				margin-bottom: 32px
				display: grid
				grid-template-columns: repeat(3, 1fr)
				grid-gap: 32px
			.col-2
				display: grid
				grid-template-columns: 1fr 1fr
				grid-column-gap: 32px
		.title
			font-size: 18px
			position: sticky
			top: -1px
			z-index: 3
			padding-top: 24px
			background: $background
			span
				position: relative
				z-index: 2
				@include textGradient
	.product, .menu
		margin-top: 6px
		display: flex
		align-items: baseline
		@include transition
		&.hide
			opacity: .2
		&-head
			width: 100%
			.product-name, .menu-name
				position: relative
				em
					-webkit-box-decoration-break: clone
					background: $background
					position: relative
					.product-cooking_time
						display: inline-block
						font-size: 12px
						background: $label
						border-radius: $small-radius
						padding: 0px 2px
						img
							width: 12px
							position: relative
							top: 2px
							margin-right: 2px
				&:before
					content: ""
					display: block
					width: calc(100% - 6px)
					border-bottom: 1px solid $grey
					position: absolute
					top: 13px
			.product-description
				font-weight: 300
				font-size: 12px
				line-height: 1.3
				padding-right: 16px
				color: $grey-text
		&-type
			white-space: nowrap
			font-weight: 300
			font-size: 12px
			min-width: 60px
			margin-left: 4px
		&-price
			font-weight: 600
			text-align: right
	.product-sushi
		text-align: center
		align-items: center
		flex-direction: column
		&-img
			width: 100%
		&-name
			font-size: 14px
			font-weight: 600
		&-price
			@include textGradient
		&-description
			font-size: 12px
			line-height: 1.4
			white-space: pre-wrap
.menu
	flex-direction: column
	&-head
		display: flex
		position: sticky
		background: $background
		padding: 4px 0
		top: 50px
	&-name
		width: 100%
		font-weight: 600
	&-detail
		margin-bottom: 16px
		&-title
			margin-bottom: 2px
		ul li
			margin-top: 2px
			&:before
				content: "·"
				margin: 0 8px 0 12px

.quantity-message
	border-radius: $medium-radius
	display: flex
	align-items: center
	padding: 8px
	background: $white
	position: relative
	margin: 32px 2px 2px
	&::before
		content: ""
		position: absolute
		width: calc(100% + 4px)
		height: calc(100% + 4px)
		left: -2px
		top: -2px
		z-index: -1	
		background: $gradient
		border-radius: 7px
	img
		margin: 0 8px 0 4px
	p